export function getBlockName(keys) {
  if (keys.includes("declaration_date")) {
    return "О декларации";
  } else if (keys.includes("movement_type_code" || "reg_date_a")) {
    return "Основная информация";
  } else if (keys.includes("declarant_first_name")) {
    return "Декларант";
  } else if (keys.includes("customs_agent_first_name")) {
    return "Таможенный представитель";
  } else if (keys.includes("cargo_route_json")) {
    return "Орган отправления";
  } else if (keys.includes("transports_json" || "transports_purpose_code")) {
    return "Транспортные средства";
  } else if (keys.includes("ware_range")) {
    return "Представленные документы";
  } else if (keys.includes("tn_ved")) {
    return "Товар";
  } else if (keys.includes("teenager_json")) {
    return "ФИО лица, не достигшего 16-ти";
  } else if (keys.includes("about_wares_json")) {
    return "Общие хар-ки товаров";
  } else if (keys.includes("transport_parts_json")) {
    return "Часть транспортного средства";
  } else if (keys.includes("money_exists")) {
    return "Денежные средства (инструменты)";
  }
  {
    return "БЛОК НЕ ЗАРЕГИСТРИРОВАН";
  }
}
