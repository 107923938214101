export function getBlockName(keys) {
  if (keys.includes("remark")) {
    return "О декларации";
  } else if (keys.includes("previous_customs_procedure")) {
    return "Основная информация";
  } else if (keys.includes("declarant_json")) {
    return "Заявитель";
  } else if (keys.includes("sender_json")) {
    return "Отправитель";
  } else if (keys.includes("recipient_json")) {
    return "Получатель";
  } else if (keys.includes("filler_json")) {
    return "Сведение о лице заполнившем";
  } else if (keys.includes("ware_location_json")) {
    return "Местонахождение товаров";
  } else if (keys.includes("doc_code")) {
    return "Документы";
  } else if (keys.includes("tn_ved") || keys.includes("article")) {
    return "Товар";
  } else if (keys.includes("invoice_currency_letter")) {
    return "Общие характеристики товара";
  } else if (keys.includes("guarantees_json")) {
    return "Обеспечение";
  } else {
    return "БЛОК НЕ ЗАРЕГИСТРИРОВАН";
  }
}
